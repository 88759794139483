import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ClientLogos from "./components/ClientLogos";
import CompanyHeader from "./components/CompanyHeader";
import FAQ from "./components/Faq";
import MenuCost from "./components/MenuCost";
import FeaturesSection from "./components/FeaturesSection";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import PricingPlans from "./components/PricingPlans";
import WaveDivider from "./components/WaveDivider";
import HowItWorks from './components/HowItWorks';
import Jobs from './components/Jobs';
import JobHeader from './components/JobHeader';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Navbar />
        <Routes>
        <Route
            path="/"
            element={
              <>
              {/* Main route components */}
              <WaveDivider />
                <FeaturesSection />
                <CompanyHeader />
                <MenuCost />
                <PricingPlans />
                <HowItWorks />
                <JobHeader />
                <ClientLogos />
                <FAQ />
                {/* Helmet for SEO on the main route */}
                <Helmet>
                  <title>Sanad App</title>
                  <meta name="description" content="Welcome to سند الذكي, your trusted smart accounting solution. Explore our features, pricing plans, and how we can help your business grow." />
                  <meta name="keywords" content="Smart Accounting, سند الذكي, Features, Pricing, Clients, FAQ" />
                </Helmet>
              </>
            }
          />
          {/* Define other routes without affecting the first route's title */}
          <Route path='/main' element={<WaveDivider />} />
          {/* <Route path="/goals" element={<MenuGoals />} /> */}
          <Route path="/packages" element={<PricingPlans />} />
          <Route path='/works' element={<HowItWorks />} />
          <Route path="/whyUs" element={<FeaturesSection />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/clients" element={<ClientLogos />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;
