import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy container  mx-auto p-6 flex flex-col items-start justify-start" dir="rtl">
    <h1 className="text-3xl font-bold mb-4">سياسة الخصوصية</h1>
    <p>نحن نحترم خصوصية معلوماتك الشخصية ونعمل على حمايتها. في هذا القسم، ستجد كيف نقوم بجمع واستخدام بياناتك.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">جمع البيانات</h2>
    <p>نقوم بجمع بيانات مثل الاسم، البريد الإلكتروني، وغيرها من البيانات عند تسجيلك على موقعنا.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">استخدام البيانات</h2>
    <p>نستخدم بياناتك لتحسين تجربتك على الموقع ولأغراض التحليل والتسويق.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">مشاركة البيانات</h2>
    <p>نحن لا نشارك بياناتك الشخصية مع أطراف خارجية إلا عند الضرورة وبموافقتك.</p>

    <h2 className="text-2xl font-semibold mt-6 mb-2">حماية البيانات</h2>
    <p>نعمل على حماية بياناتك من أي وصول غير مصرح به باتباع أحدث إجراءات الأمان.</p>

    <p className="mt-4">إذا كان لديك أي استفسار حول سياستنا، يرجى التواصل معنا.</p>
</div>

    
    );
};

export default PrivacyPolicy;