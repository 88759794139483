import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <>
      <footer className="bg-emerald-600 text-white py-10" dir="rtl">
        {/* Existing Footer Content */}
        <div className="flex justify-center">
          <div className="border-b w-[80%] max-w-7xl mb-5 pb-6">
            <img src="/logo_inverted.svg" alt="Logo" className="h-10" />
          </div>
        </div>

        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-6 w-[80%] md:px-0">
          {/* Column 1 - Company Description */}
          <div>
            <h3 className="font-bold text-2xl mb-10"> محاسب سند الذكي</h3>
            <p className="text-sm w-[90%] font-semibold">
              فريق من المحاسبين مع نظام محاسبي مدعوم بالذكاء الصناعي مخصص لشركتكك لراحة بالك وضمان دقة معاملاتك المالية
            </p>
            <div className='mt-4'>
              <img src="/saudi_business.png" alt="Saudi Business" className="w-40 h-auto" />
              <p className="text-l pt-2 text-sm w-[90%] font-semibold">رقم التوثيق : 0000061560</p>

            </div>
          </div>

          {/* Column 2 - Site Sections */}
          <div className="w-full md:w-[90%] ">
            <h3 className="font-bold text-2xl mb-10">أقسام الموقع</h3>
            <ul className="space-y-2 text-sm font-semibold">
              <li>الرئيسية</li>
              <li>لماذا نحن </li>
              <li> كيف نعمل</li>
              <li>الباقات</li>
              <li>عملاءنا  </li>
              <li>الوظائف</li>
              <li>المقالات</li>
            </ul>
          </div>

          {/* Column 3 - Contact Information */}
          <div>
            <h3 className="font-bold text-2xl mb-10">تواصل معنا</h3>
            <ul className="space-y-2 text-sm font-semibold">
              <li className="flex items-center">
                <i className="fab fa-whatsapp text-xl ml-2"></i>
                <span>واتس اب</span>
                <span className="mr-2">
                  <a
                    href="https://wa.me/966544740441?text=السلام+عليكم،+أحتاج+أعرف+معرفة+معلومات+أكثر+عن+فريق+عمل+المحاسبين+والنظام+المحاسبي"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    966544740441
                  </a>
                </span>
              </li>
              <li className="flex items-center">
                <i className="fas fa-phone-alt text-xl ml-2"></i>
                <span>الهاتف الجوال</span>
                <span className="mr-2">966544740441</span>
              </li>
              <li className=" pt-7">
                <img className="w-40 h-auto" src='/mobile&web.png' />
              </li>

            </ul>
          </div>

          {/* Column 4 - Company Location */}
          <div>
            <h3 className="font-bold text-2xl mb-10">موقع الشركة</h3>
            <p className="text-l leading-relaxed font-semibold">
              <i className="fas fa-map-marker-alt ml-2"></i>
              المملكة العربية السعودية - الرياض - طريق الملك عبدالله
            </p>
          </div>
        </div>

        {/* Bottom Section - Social Media and Copyright */}
        <div className="border-t mt-8 pt-4 w-[80%] max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-center md:px-0">

          <p className="text-sm font-semibold text-center mb-4 md:mb-0">

            جميع الحقوق محفوظة © 2024           <a href="/privacy-policy" className="ext-sm font-semibold ">سياسة الخصوصية</a>
            - يدار بواسطة شركة سند
          </p>

          <div className="flex space-x-4 text-xl">
            <p className="ml-4">تابعنا على: </p>
            <a
              href="https://x.com/i/flow/login?redirect_after_login=%2Fsanad_comp"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300"
            >
              <i className="fab fa-twitter"></i>
            </a>

            <a
              href="https://www.instagram.com/sanad_comp/?igshid=YmMyMTA2M2Y%3D"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-gray-300"
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </footer>

      {/* Floating WhatsApp Icon */}
      <a
        href="https://wa.me/966544740441?text=السلام+عليكم،+أحتاج+أعرف+معلومات+أكثر+عن+فريق+عمل+المحاسبين+والنظام+المحاسبي"
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-8 left-8 bg-green-300 text-white p-4 rounded-full shadow-lg hover:bg-emerald-500 transition duration-300 z-50 animate-bounce"
      >
        <i className="fab fa-whatsapp text-3xl"></i>
      </a>

    </>
  );
};

export default Footer;
