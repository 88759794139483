import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const JobHeader = () => {
  const controls = useAnimation(); // Controls animation state
  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger when 50% of the component is in view
  });

  // Start animation when the component is in view and reverse when out
  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 100 });
    }
  }, [controls, inView]);

  return (
    <>
      {/* SEO Metadata */}
      <Helmet>
        <title>Wave Divider - Your Site Name</title>
        <meta name="description" content="Discover how to send invoices with ease using our smart accounting software." />
      </Helmet>
      <motion.div
        ref={ref} // Hook the ref to the div to observe when it's in view
        animate={controls} // Bind animation control to this div
        initial={{ opacity: 0, y: 100 }} // Initial state
        transition={{ duration: 0.6, ease: "easeOut" }} // Smooth transition
      >

        {/* New Section */}
        <div className="text-center pt-32 mb-12">
          <h2 dir="rtl" className="text-4xl md:text-5xl font-bold mb-4 p-2">
            فريقنا هو الأكثر موهبة في مجال التقنيه الماليه
          </h2>
          <motion.button
            className="bg-gray-800 text-white py-3 mt-10 px-8 rounded-full hover:bg-gray-700 transition"
            whileHover={{ scale: 1.1 }} // Hover effect
            onClick={() => window.open('https://forms.clickup.com/9005174595/f/8cc03u3-5515/3X5KA88Y5DVUVQ9ZRI', '_blank', 'noopener noreferrer')}

          >
            اشترك معنا
          </motion.button>
        </div>
      </motion.div>
    </>
  );
};

export default JobHeader;
